import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { app, analytics } from './firebase';
import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import MainPanel from './main_panel';
import LandingPage from './landing_page';
import * as utils from './utils'
import CircularProgress from '@mui/joy/CircularProgress';

import { BrowserRouter as Router, Route, useLocation, useNavigate, Routes } from 'react-router-dom';


const App = () => {
  const [filters, setFilters] = useState({});
  const [dropdownValue, setDropdownValue] = useState(''); // depth
  const [depthValues, setDepthValues] = useState([]); // depth choices
  const [overviewStats, setOverviewStats] = useState({'total_params':0, 'total_latency':0, 'max_memory_allocated':0});

  const [helpInformation, setHelpInformation] = useState("loading model into darkspark viewer...");
  const [isThinking, setIsThinking] = useState(true);

  const [actsDisplayStatus, setActsDisplayStatus] = React.useState('volumes');

  const [actsDisplayOptions, setActsDisplayOptions] = React.useState([]);



  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} /> 
        <Route 
          path="/models" 
          element={
            <div style={{ display: 'flex', 
                          height: '100%', 
                          width: '100%',
                          WebkitTouchCallout: 'none',
                          WebkitUserSelect: 'none',
                          userSelect: 'none',
                          }}>
              <div style={{ userSelect: 'none' }}>
                <Sidebar onFilterChange={setFilters}
                          setDropdownValue={setDropdownValue}
                          dropdownValue={dropdownValue}
                          depthValues={depthValues}
                          overviewStats={overviewStats}
                          setActsDisplayStatus={setActsDisplayStatus}
                          actsDisplayStatus={actsDisplayStatus}
                          actsDisplayOptions={actsDisplayOptions}
                          />
              </div>
              <div className="main-panel" style={{overflow:"hidden"}}>
                <MainPanel filters={filters} setDropdownValue={setDropdownValue} 
                                              setDepthValues={setDepthValues} 
                                              setOverviewStats={setOverviewStats}
                                              setHelpInformation={setHelpInformation}
                                              setActsDisplayStatus={setActsDisplayStatus}
                                              setActsDisplayOptions={setActsDisplayOptions}
                                              setIsThinking={setIsThinking}
                                              />
              </div>
              <div style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                color: 'grey',
                background: 'transparent',
                zIndex: 100,
                width: '200px', 
                fontSize: '12px',
                userSelect: 'none',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div style={{
                  wordWrap: 'break-word',
                  flex: 1,
                  marginRight: '10px'
                }}>
                  {helpInformation}
                </div>
                {isThinking && 
                  <CircularProgress variant="solid" color="neutral" size='md'/>
                }
              </div>

            </div>
        } />
      </Routes>
    </Router>
  );
};

export default App;
