import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Stack } from '@mui/material';
import { Google as GoogleIcon, GitHub as GitHubIcon } from '@mui/icons-material'; // Importing icons
import { FcGoogle } from 'react-icons/fc'; // Import the colored Google icon from react-icons

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import ModelList from './ModelList'; 
import * as utils from './utils';

const LandingPage = () => {
  const [modelOptions, setModelOptions] = useState([]);
  const [user, setUser] = useState(null);

  // Firebase authentication
  const auth = getAuth();

  // Check if the user is logged in on app load
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [auth]);

  // Login with Google
  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('Google Sign-in successful:', result);
      })
      .catch((error) => {
        console.error('Google Sign-in error:', error);
      });
  };

  // Login with GitHub
  const handleGithubLogin = () => {
    const provider = new GithubAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('GitHub Sign-in successful:', result);
      })
      .catch((error) => {
        console.error('GitHub Sign-in error:', error);
      });
  };

  // Logout function
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User logged out');
        setUser(null);
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/model_specs_overview.json`)
      .then((response) => response.json())
      .then((data) => {
        let data_as_array = Object.keys(data).map((model_name) => data[model_name]);
        
        data_as_array.forEach((d) => {
          d.library = d?.trace_metadata?.library ?? "none";
          d.library = d.library === "transformers" ? utils.transformers_str_w_emoji : d.library;
          d.library = d.library === "diffusers" ? utils.diffusers_str_w_emoji : d.library;
        });

        data_as_array.sort((a, b) => {
          const libraryCompare = a.library.localeCompare(b.library, undefined, { sensitivity: 'base' });
          if (libraryCompare !== 0) {
            return libraryCompare;
          }
          return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });

        setModelOptions(data_as_array);
      });
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <AppBar position="static" sx={{ backgroundColor: '#333' }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box 
              component="img" 
              src="/logos_light/android-chrome-192x192.png"
              alt="Darkspark Logo"
              sx={{ height: 40 }}
            />
            <Typography variant="h6" component="div" sx={{ marginLeft: 1 }}>
              darkspark
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Box
        sx={{
          padding: '20px', // Ensure padding inside the page
          width: '100vw',
          height: 'calc(100vh - 64px)', // Full viewport height minus AppBar
          overflow: 'hidden', // Prevent content overflow
          boxSizing: 'border-box', // Include padding in the height calculation
        }}
      >
        <Stack direction="row" spacing={2} sx={{ height: '100%' }}>
          {/* Left Column */}
          <Box
            sx={{
              flex: '1 1 300px', 
              backgroundColor: '#e0e0e0',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for better definition
              boxSizing: 'border-box', // Ensure padding is included
              overflow: 'auto', // Ensure scrollability if content exceeds height
            }}
          >
            <Typography variant="h5" gutterBottom>View your own model</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Add one line to trace your PyTorch model and view it locally
            </Typography>
            <SyntaxHighlighter language="python" style={vscDarkPlus}>
              {code_demo_str}
            </SyntaxHighlighter>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ mt: 4, fontStyle: 'italic' }}
            >
              {user
                ? `You're logged in as ${user.email}. We'll let you know when the public repo is ready! For now, check out our pretraced models -->`
                : 'Support for tracing your own models is coming soon. Sign up to get updated when the package is ready!'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: 2 }}>
              {user ? (
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: '18px',
                    padding: '10px 20px',
                    backgroundColor: 'white',
                    color: '#555',
                    borderColor: '#d9d9d9',
                    '&:hover': {
                      backgroundColor: 'lightgrey',
                    },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<FcGoogle />}
                    sx={{
                      fontSize: '16px',
                      padding: '6px 12px',
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      borderColor: '#d9d9d9',
                      backgroundColor: 'white',
                      color: '#555',
                      '&:hover': {
                        borderColor: '#bbb',
                        backgroundColor: 'lightgrey',
                      },
                    }}
                    onClick={handleGoogleLogin}
                  >
                    Sign up with Google
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<GitHubIcon />}
                    sx={{
                      fontSize: '16px',
                      padding: '6px 12px',
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      backgroundColor: '#333',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#444',
                      },
                    }}
                    onClick={handleGithubLogin}
                  >
                    Sign up with GitHub
                  </Button>
                </>
              )}
            </Box>
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              flexGrow: 1, // Take up remaining width
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              padding: '0px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              boxSizing: 'border-box',
              overflow: 'hidden',
            }}
          >
            <ModelList modelOptions={modelOptions} />
          </Box>
        </Stack>
      </Box>

    </div>
  );
};

export default LandingPage;

const code_demo_str = `import darkspark
import timm
import torch

model = timm.create_model("efficientnet_b0")
inputs = torch.randn(1,3,224,224)

with darkspark.Tracer():  # <-- wrap your code with this line
  out = model(inputs)

# interactive diagram now available at localhost
`;
