import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import pako from 'pako';
import * as utils from './utils';

const FeatureTooltip = ({ featureTooltipObject }) => {
  const canvasRefsPos = useRef([]);
  const canvasRefsNeg = useRef([]);
  const [overlayData, setOverlayData] = useState(null);
  const [loadedImagesPos, setLoadedImagesPos] = useState(new Set());
  const [loadedImagesNeg, setLoadedImagesNeg] = useState(new Set());

  const [shouldLoadContent, setShouldLoadContent] = useState(false);
  // const mouseTimer = useRef(null);

  // const handleMouseMove = (e) => {
  //   const newPosition = { x: e.clientX, y: e.clientY };
    
  //   // If mouse moved more than 5px, reset the timer
  //   if (mousePosition && 
  //       Math.hypot(newPosition.x - mousePosition.x, newPosition.y - mousePosition.y) > 10) {
  //       clearTimeout(mouseTimer.current);
  //       setShouldLoadContent(false);
  //   }
    
  //   setMousePosition(newPosition);
    
  //   // Start new timer
  //   clearTimeout(mouseTimer.current);
  //   mouseTimer.current = setTimeout(() => {
  //     setShouldLoadContent(true);
  //   }, 50);
  // };



  const img_dim = 80
  // const img_dim = Math.floor(utils.interp(window.innerWidth, [1200, 1600], [80,96]))
  const num_imgs_to_show = Math.floor(utils.interp(window.innerWidth, [1200, 1600], [8,16]))

  const getImageStyle = (index, isNegative) => {
    if (!overlayData || (overlayData.fn_type !== "linear" && overlayData.fn_type !== "attnMatrix")) {
      return { maxHeight: `${img_dim}px` };
    }

    let adjustedHeight = img_dim

    return { maxHeight: `${adjustedHeight}px`, height: `${adjustedHeight}px` };
  };

  const drawOverlay = (canvas, index, isNegative) => {
    if (!canvas || !overlayData) return;
    
    const ctx = canvas.getContext('2d');
    const img = canvas.previousSibling;
    canvas.width = img.width;
    
    function color_lookup(v) {
      let {r, g, b} = utils.rgb_from_z(v/10);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    }

    const fn_type = overlayData["fn_type"];

    if (fn_type === "conv2d") {
      let overlayDataArray = isNegative ? overlayData["actmaps_neg"][index] : overlayData["actmaps"][index];
      canvas.height = img.height;

      const cellWidth = canvas.width / overlayDataArray[0].length;
      const cellHeight = canvas.height / overlayDataArray.length;
      
      overlayDataArray.forEach((row, y) => {
        row.forEach((value, x) => {
          ctx.fillStyle = color_lookup(value);
          ctx.fillRect(x * cellWidth, y * cellHeight, cellWidth, cellHeight);
        });
      });
    } else if (fn_type === "linear") {
      let overlayDataArray = isNegative ? overlayData["actmaps_neg"][index] : overlayData["actmaps"][index];
    
      if (typeof overlayDataArray === 'number') {
        overlayDataArray = [overlayDataArray];
      }

      if ((overlayDataArray[0] !== undefined) && overlayDataArray[0][0] !== undefined) {
        canvas.height = img.height;
        const height = overlayDataArray.length;
        const width = overlayDataArray[0].length;
        const cellWidth = canvas.width / width;
        const cellHeight = canvas.height / height;
        
        overlayDataArray.forEach((row, y) => {
          row.forEach((cell, x) => {
            ctx.fillStyle = color_lookup(cell);
            ctx.fillRect(x * cellWidth, y * cellHeight, cellWidth, cellHeight);
          });
        });
      } else {
        const gridSize = Math.floor(Math.sqrt(overlayDataArray.length));
        const extraTokens = overlayDataArray.length - (gridSize * gridSize);
        const numRows = gridSize + (extraTokens > 0 ? 1 : 0);
        
        canvas.height = (img.height * numRows) / gridSize;

        const cellWidth = canvas.width / gridSize;
        const cellHeight = canvas.height / numRows;

        overlayDataArray.forEach((value, i) => {
          const x = i % gridSize;
          const y = Math.floor(i / gridSize);
          ctx.fillStyle = color_lookup(value);
          ctx.fillRect(x * cellWidth, y * cellHeight, cellWidth, cellHeight);
        });
      }
    } else if (fn_type === "attnMatrix") {
      const q_pts = isNegative ? overlayData["q_pts_neg"][index] : overlayData["q_pts"][index];
      const k_pts = isNegative ? overlayData["k_pts_neg"][index] : overlayData["k_pts"][index];
      
      const gridSize = overlayData["grid_size"];
      const extraTokens = overlayData["extra_tokens"];
      const numRows = gridSize + (extraTokens > 0 ? 1 : 0);

      canvas.height = (img.height * numRows) / gridSize;
      
      const cellWidth = canvas.width / gridSize;
      const cellHeight = canvas.height / numRows;
      const pointSize = Math.min(cellWidth, cellHeight) / 4;
      
      const adjustCoords = ([y, x]) => {
        const linearIndex = y * gridSize + x;
        return [
          Math.floor(linearIndex / gridSize),
          linearIndex % gridSize
        ];
      };

      ctx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
      ctx.lineWidth = 1;
      
      q_pts.forEach((qPoint, i) => {
        const [qy, qx] = adjustCoords(qPoint);
        const [ky, kx] = adjustCoords(k_pts[i]);
        
        const startX = (kx + 0.5) * cellWidth;
        const startY = (ky + 0.5) * cellHeight;
        const endX = (qx + 0.5) * cellWidth;
        const endY = (qy + 0.5) * cellHeight;
        
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
        
        const dx = endX - startX;
        const dy = endY - startY;
        const length = Math.sqrt(dx * dx + dy * dy);
        const unitX = dx / length;
        const unitY = dy / length;
        
        const chevronSpacing = 15;
        const numChevrons = Math.max(2, Math.floor(length / chevronSpacing));
        const actualSpacing = length / (numChevrons + 1);
        const chevronSize = 2.5;
        
        for (let j = 1; j <= numChevrons; j++) {
          const t = j / (numChevrons + 1);
          const x = startX + t * dx;
          const y = startY + t * dy;
          
          ctx.beginPath();
          ctx.moveTo(
            x - chevronSize * (unitX + unitY),
            y - chevronSize * (unitY - unitX)
          );
          ctx.lineTo(x, y);
          ctx.lineTo(
            x - chevronSize * (unitX - unitY),
            y - chevronSize * (unitY + unitX)
          );
          ctx.stroke();
        }
      });

      ctx.fillStyle = 'rgba(0, 255, 0, 0.7)';
      q_pts.forEach(point => {
        const [y, x] = adjustCoords(point);
        ctx.beginPath();
        ctx.arc(
          (x + 0.5) * cellWidth,
          (y + 0.5) * cellHeight,
          pointSize,
          0,
          2 * Math.PI
        );
        ctx.fill();
      });
      
      ctx.fillStyle = 'rgba(255, 0, 255, 0.7)';
      k_pts.forEach(point => {
        const [y, x] = adjustCoords(point);
        ctx.beginPath();
        ctx.arc(
          (x + 0.5) * cellWidth,
          (y + 0.5) * cellHeight,
          pointSize,
          0,
          2 * Math.PI
        );
        ctx.fill();
      });
    }
  };

  useEffect(() => {
    fetch(featureTooltipObject.overlaysPath)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => {
        const uint8Array = new Uint8Array(arrayBuffer);
        const decompressed = pako.ungzip(uint8Array, { to: 'string' });
        const overlays = JSON.parse(decompressed);
        setOverlayData(overlays);
      });
  }, [featureTooltipObject.overlaysPath]);

  useEffect(() => {
    if (!overlayData) return;

    canvasRefsPos.current.forEach((canvas, index) => {
      if (loadedImagesPos.has(index)) {
        drawOverlay(canvas, index, false);
      }
    });

    canvasRefsNeg.current.forEach((canvas, index) => {
      if (loadedImagesNeg.has(index)) {
        drawOverlay(canvas, index, true);
      }
    });
  }, [overlayData, loadedImagesPos, loadedImagesNeg]);

  const limitedImagesPos = featureTooltipObject.image_paths_pos.slice(0, num_imgs_to_show);
  const limitedImagesNeg = featureTooltipObject.image_paths_neg.slice(0, num_imgs_to_show);

  return (
    <Card style={{
      width:`${(img_dim+8)*num_imgs_to_show+100}px`,
      userSelect: "none",
      pointerEvents: "none",
      }}>
      <CardContent>
        {/* <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>
          {`Dataset exemplars for ${overlayData?.fn_type==="attnMatrix"?"head":"channel" } ${featureTooltipObject.channel_ix} — tensor ${featureTooltipObject.tensor_id.slice(4)}`}
        </Typography> */}

        
        {overlayData?.fn_type === "attnMatrix" && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, marginBottom: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div style={{ 
                width: '12px', 
                height: '12px', 
                backgroundColor: 'rgba(0, 255, 0, 0.7)', 
                borderRadius: '50%' 
              }}/>
              <Typography variant="caption">Query (Q)</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div style={{ 
                width: '12px', 
                height: '12px', 
                backgroundColor: 'rgba(255, 0, 255, 0.7)', 
                borderRadius: '50%' 
              }}/>
              <Typography variant="caption">Key (K)</Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
          <p style={{ width:'70px', fontSize:'12px' }}>
            strongest <span style={{ color: 'red' }}> positive</span> dataset {overlayData?.fn_type === "attnMatrix" ? "QK matches" : "activations"}
          </p>
          {limitedImagesPos.map((src, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <img
                src={src}
                alt={src}
                style={getImageStyle(index, false)}
                onLoad={(e) => {
                  if (canvasRefsPos.current[index]) {
                    canvasRefsPos.current[index].width = e.target.width;
                    canvasRefsPos.current[index].height = e.target.height;
                    setLoadedImagesPos(prev => new Set(prev).add(index));
                  }
                }}
              />
              <div style={{position: "relative"}}>
                <img
                  src={src}
                  alt={src}
                  style={{ 
                    ...getImageStyle(index, false),
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0.25,
                    filter: 'saturate(0)',
                  }}
                />
                <canvas
                  ref={el => canvasRefsPos.current[index] = el}
                  style={{
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none'
                  }}
                />
              </div>
            </div>
          ))}
        </Box>
        <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', my: 2 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <p style={{ width:'70px', fontSize:'12px'}}>
            strongest <span style={{ color: 'blue' }}> negative</span> dataset {overlayData?.fn_type === "attnMatrix" ? "QK matches" : "activations"}
          </p>
          {limitedImagesNeg.map((src, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <img
                src={src}
                alt={src}
                style={getImageStyle(index, true)}
                onLoad={(e) => {
                  if (canvasRefsNeg.current[index]) {
                    canvasRefsNeg.current[index].width = e.target.width;
                    canvasRefsNeg.current[index].height = e.target.height;
                    setLoadedImagesNeg(prev => new Set(prev).add(index));
                  }
                }}
              />
              <div style={{position: "relative"}}>
                <img
                  src={src}
                  alt={src}
                  style={{ 
                    ...getImageStyle(index, true),
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0.25,
                    filter: 'saturate(0)',
                  }}
                />
                <canvas
                  ref={el => canvasRefsNeg.current[index] = el}
                  style={{
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none'
                  }}
                />
              </div>
            </div>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FeatureTooltip;