import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Chip, Card, CardContent, CardMedia, Grid, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FixedSizeList } from 'react-window';
import * as utils from './utils'

const ModelList = ({ modelOptions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLibrary, setSelectedLibrary] = useState('');
  const [showFeatureSpace, setShowFeatureSpace] = useState(false);
  const [listHeight, setListHeight] = useState(400);

  const libaryOptions = [
    { name: "timm", display_name: "timm" },
    { name: "diffusers", display_name: utils.diffusers_str_w_emoji },
    { name: "transformers", display_name: utils.transformers_str_w_emoji }
  ];

  const filteredModels = modelOptions.filter((model) => {
    const matchesSearch = model.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLibrary = selectedLibrary === '' || model.library === selectedLibrary;
    const matchesFeatureSpace = !showFeatureSpace || model.trace_metadata.has_featurespace === true;
    return matchesSearch && matchesLibrary && matchesFeatureSpace;
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLibraryClick = (library) => {
    setSelectedLibrary(library === selectedLibrary ? '' : library);
  };

  const handleFeatureSpaceClick = () => {
    setShowFeatureSpace(!showFeatureSpace);
  };

  const renderRow = ({ index, style }) => {
    const item = filteredModels[index];
    const handleClick = () => {
      // window.open(`models/?model=${item.name}`, '_blank', 'noopener,noreferrer');
      window.location.href = `models/?model=${item.name}`
    };

    return (
      <Grid item xs={12} key={index} style={style}>
        <Card 
          sx={{ 
            display: 'flex', 
            width: '100%', 
            cursor: 'pointer', 
            transition: 'background-color 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }
          }} 
          onClick={handleClick}
        >
          <CardContent sx={{ flexBasis: '220px', flexShrink: 0, width: "220px" }}>
            <Typography variant="h6">{item.name}</Typography>
            <Typography variant="body2" color="textSecondary">{item.library}</Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{
              flexGrow: 1,
              objectFit: 'cover',
              objectPosition: 'left'
            }}
            image={`${process.env.PUBLIC_URL}/data/model_thumbnails/darkspark_thumbnail_${item.name}.png`} 
            alt={item.name}
          />
        </Card>
      </Grid>
    );
  };

  useEffect(() => {
    const calculateListHeight = () => {
      const availableHeight = window.innerHeight - 300; // just eyeballed
      setListHeight(availableHeight);
    };
    calculateListHeight();
    window.addEventListener('resize', calculateListHeight);
    return () => window.removeEventListener('resize', calculateListHeight);
  }, []);

  return (
    <Box sx={{ backgroundColor: '#e0e0e0', padding: '20px', borderRadius: '8px', height: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Explore pre-traced models
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
        <TextField
          label="Search models"
          variant="outlined"
          sx={{ width: '400px' }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          {libaryOptions.map((library) => (
            <Chip
              key={library.display_name}
              label={library.display_name}
              clickable
              color={selectedLibrary === library.display_name ? 'primary' : 'default'}
              onClick={() => handleLibraryClick(library.display_name)}
            />
          ))}
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                microscope
                <Tooltip title="activations available for this model" arrow>
                  <InfoOutlinedIcon sx={{ fontSize: 16, ml: 0.5 }} />
                </Tooltip>
              </Box>
            }
            clickable
            color={showFeatureSpace ? 'primary' : 'default'}
            onClick={handleFeatureSpaceClick}
          />
        </Box>
      </Box>

      <Box sx={{ height: `${listHeight}px`, backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
        <FixedSizeList
          height={listHeight}
          width="100%"
          itemSize={140}
          itemCount={filteredModels.length}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </Box>
  );
};

export default ModelList;