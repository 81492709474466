import React, { useState, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { ChevronDown } from 'lucide-react';
import { createPortal } from 'react-dom';

const THUMB_SIZE = 32;
const ROW_PADDING = 6;
const ROW_HEIGHT = THUMB_SIZE + (ROW_PADDING * 2);

const ImageDropdown = ({ tracedImgsList, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const Row = ({ index, style }) => {
    const imgPath = tracedImgsList[index];
    const isSelected = imgPath === value;
    const isHovered = hoveredIndex === index;
    
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          padding: `${ROW_PADDING}px 12px`,
          backgroundColor: isHovered ? '#f5f5f5' : isSelected ? '#f0f7ff' : 'white',
          cursor: 'pointer',
          transition: 'background-color 0.2s'
        }}
        className='imageDropdown'
        onClick={() => {
          onChange({ target: { value: imgPath } });
          setIsOpen(false);
        }}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <img
          src={`/data/trace_imgs/${imgPath}.png`}
          alt=""
          style={{
            width: `${THUMB_SIZE}px`,
            height: `${THUMB_SIZE}px`,
            objectFit: 'cover',
            borderRadius: '4px',
            border: '1px solid #e0e0e0'
          }}
        />
        <span style={{ marginLeft: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {imgPath}
        </span>
      </div>
    );
  };

  const getDropdownStyles = () => {
    if (!buttonRef.current) return {};
    
    const rect = buttonRef.current.getBoundingClientRect();
    return {
      position: 'fixed',
      width: rect.width,
      top: rect.bottom + 4,
      left: rect.left
    };
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <button
        ref={buttonRef}
        type="button"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: `${ROW_PADDING}px 12px`,
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          backgroundColor: 'white',
          cursor: 'pointer'
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value ? (
          <>
            <img
              src={`/data/trace_imgs/${value}.png`}
              alt=""
              style={{
                width: `${THUMB_SIZE}px`,
                height: `${THUMB_SIZE}px`,
                objectFit: 'cover',
                borderRadius: '4px',
                border: '1px solid #e0e0e0'
              }}
            />
            <span style={{ marginLeft: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {value}
            </span>
          </>
        ) : (
          <span style={{ color: '#666' }}>Select an image</span>
        )}
        <ChevronDown
          style={{
            marginLeft: 'auto',
            transform: isOpen ? 'rotate(180deg)' : 'none',
            transition: 'transform 0.2s'
          }}
        />
      </button>

      {isOpen && createPortal(
        <div 
          style={{
            ...getDropdownStyles(),
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            zIndex: 1000
          }}
          onMouseLeave={() => {
            setIsOpen(false);
            setHoveredIndex(null);
          }}
        >
          <FixedSizeList
            height={Math.min(320, tracedImgsList.length * ROW_HEIGHT)}
            itemCount={tracedImgsList.length}
            itemSize={ROW_HEIGHT}
            width="100%"
          >
            {Row}
          </FixedSizeList>
        </div>,
        document.body
      )}
    </div>
  );
};

export default ImageDropdown;