import React, { useState, useEffect, useRef } from 'react';
import { GripHorizontal, Minimize2, Maximize2, ArrowUpRight, Menu } from 'lucide-react';
import { globals } from './globals';

const DraggableWindow = ({ 
  title, 
  children, 
  initialPosition = { x: 20, y: 20 },
  collapsedIcon: CollapsedIcon = Menu,
  initiallyCollapsed = false,
  minWidth = 280,
  onZIndexChange = () => {}
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
  const [isPinned, setIsPinned] = useState(!initiallyCollapsed);
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [zIndex, setZIndex] = useState(100);
  const dragStart = useRef({ x: 0, y: 0 });
  const windowRef = useRef(null);
  const expandTimeoutRef = useRef(null);
  const collapseTimeoutRef = useRef(null);
  const lastClickTime = useRef(0);

  const handleMouseDown = (e) => {
    if (e.target.closest('.pin-button')) return;
    
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastClickTime.current;
    
    if (timeDiff < 300) {
      e.preventDefault();
      togglePin();
      return;
    }
    
    lastClickTime.current = currentTime;
    setIsDragging(true);
    const rect = windowRef.current.getBoundingClientRect();
    dragStart.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  const togglePin = () => {
    const newPinnedState = !isPinned;
    setIsPinned(newPinnedState);
    // if (!newPinnedState) { // close immediately
    //   setIsCollapsed(true);
    // } 
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        const newX = e.clientX - dragStart.current.x;
        const newY = e.clientY - dragStart.current.y;
        setPosition({ x: newX, y: newY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseEnter = () => {
    clearTimeout(collapseTimeoutRef.current);
    if (isCollapsed && !isDragging) {
      expandTimeoutRef.current = setTimeout(() => {
        setIsCollapsed(false);
      }, 100);
    }
    setZIndex(prevZIndex => {
      globals.currentHighestWindowZ += 1;
      onZIndexChange(globals.currentHighestWindowZ);
      return globals.currentHighestWindowZ;
    });
    globals.clear_highlighted() // deselects any main-panel things currently mouseovered, otherwise they remain selected when enter this window
  };

  const handleMouseLeave = () => {
    clearTimeout(expandTimeoutRef.current);
    if (!isPinned && !isDragging) {
      collapseTimeoutRef.current = setTimeout(() => {
        setIsCollapsed(true);
      }, 200);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(expandTimeoutRef.current);
      clearTimeout(collapseTimeoutRef.current);
    };
  }, []);

  return (
    <div
      ref={windowRef}
      className='draggableWindow'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: isCollapsed ? '40px' : 'auto',
        minWidth: isCollapsed ? '40px' : `${minWidth}px`,
        backgroundColor: 'white',
        border: '1px solid #d1d5db',
        borderRadius: '8px',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        transition: isDragging ? 'none' : 'width 0.2s ease-in-out',
        userSelect: 'none',
        zIndex: zIndex
      }}
    >
      <div 
        style={{
          backgroundColor: '#f3f4f6',
          padding: '8px',
          cursor: 'move',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onMouseDown={handleMouseDown}
        title={isPinned ? "Double-click to unpin from open position" : "Double-click to pin to open position"}
      >
        {isCollapsed ? (
          <CollapsedIcon size={24} style={{ color: '#6b7280', margin: 'auto' }} />
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
              <GripHorizontal size={20} style={{ color: '#6b7280', marginRight: '8px', flexShrink: 0 }} />
              <span style={{
                fontSize: '14px',
                color: '#374151',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                {title}
              </span>
            </div>
            <button
              className="pin-button"
              onClick={togglePin}
              title={isPinned ? "Unpin" : "Pin to open position"}
              style={{
                color: isPinned ? '#3b82f6' : '#6b7280',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: 0,
                marginLeft: '8px',
                flexShrink: 0,
              }}
            >
              <ArrowUpRight size={20} />
            </button>
          </>
        )}
      </div>
      <div 
        style={{
          maxHeight: isCollapsed ? '0' : '1200px',
          overflow: 'hidden',
          transition: 'max-height 0.2s ease-in-out',
        }}
      >
        <div style={{ padding: '16px' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DraggableWindow;